.default-row-class {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #2A2B2D;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: background-color 0.3s;
    font-size: 14px;
}

.defaulf-cell-class {
    width: 30%;
    padding: 2px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px;
    margin-bottom: 2px;
}

.default-row-class:hover {
    border-color: #F0FFF0;
    box-shadow: 0 0 5px 1px #F0FFF0;
    cursor: pointer;
}
