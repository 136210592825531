.project-show-table-container {
    background-color: #2E2E30;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #424244;
}

.project-show-table-container:hover {
    border-color: #4573d2;
    box-shadow: 0 0 5px 1px #4573d2;
}

.project-show-task-table {
    border-collapse: collapse;
    margin-bottom: 10px;
    table-layout: fixed;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: .375rem;
    background-color: #2a2b2d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.project-show-task-table thead {
    display: flex;
    justify-content: space-between;
    width: 87%;
    background-color: #333333;
    color: #FFFFFF;
}

.project-show-task-table th {
    font-weight: bold;
    text-align: left;
    padding: 8px;
    width: 24vw;
    text-decoration: underline;
}

.project-show-task-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    width: 24vw;
    text-align: left;
}

.project-show-task-table tbody tr {
    display: flex;
    justify-content: space-between;
    transition: background-color 0.3s;
}


.project-task-table-header {
    display: flex;
    width: calc(100% - 20px);
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 2px;
    background-color: #2E2E30;
    padding: 10px;
    font-weight: bold;
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
}

.project-task-index {
    /* width: 100vw; */
    padding-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 50px;
}

.project-task-table-row {
    display: flex;
    width: calc(100% - 20px);
    justify-content: space-between;
    padding-top: 2px;
    padding-bottom: 2px;
    padding: 10px;
    background-color: #2E2E30;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    align-items: center;
    font-size: 15px;
}

.no-hover:hover {
    border-color: #F0FFF0;
    box-shadow: 0 0 5px 1px #F0FFF0;
    cursor: pointer;
}

.project-task-cell {
    padding: 2px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin-top: 2px;
    margin-bottom: 2px; */
    /* display: flex; */
}

.pjt-wide {
    width: 25%;
}

.pjt-narrow {
    width: 10%;
}

.pjt-last {
    width: 10%;
    max-width: 100px;
    padding-right: 10px;
    text-overflow: unset;
}

.pjt-icons {
    text-align: right;
}

.project-show-task-detail-dropdown {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.detail-row {
    text-align: left;
}

.project-show-task-detail-dropdown-label {
    font-size: 16px;
    font-weight: bold;
    flex: 1;
    text-align: left;
    margin-left: 10px;
    margin-right: 5px;
}

.project-show-task-detail-dropdown-item {
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px;
}

.project-show-task-row-wrapper {

}

.project-show-task-row-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.project-show-task-index-no-task-message {
    margin-top: 5px;
    padding-bottom: 2px;
}
