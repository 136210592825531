.task-recommendation {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #333;
}

.task-recommendation button {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #0077cc;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.task-recommendation button:hover {
    background-color: #005ea3;
}

.task-recommendation pre {
    margin-top: 1rem;
    white-space: pre-wrap;
}

.recommended-tasks {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    font-size: 1.2rem;
    line-height: 1.5;
    width: 90%;
}

.generate-reccomended-tasks-button {
    display: inline-block;
    padding: 8px 16px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    background-color: #2E2E30;
    color: #FFFFFF;
    cursor: pointer;
    transition: background-color 250ms ease;
    margin-top: 10px;
    border: 1px solid transparent;
    animation: rainbowBorder 5s linear infinite;
}

.generate-reccomended-tasks-button:hover {
    background-color: #4C4C4E;
}

.generate-reccomended-tasks-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #FFFFFF, 0 0 0 4px #2E2E30;
}

.generate-reccomended-tasks-button:active {
    background-color: #222224;
}

.generate-reccomended-tasks-title {
    font-size: 20px;
    color: #FFFFFF;
}


  @keyframes rainbowBorder {
    0% {
      border-color: #ff0;
    }
    14% {
      border-color: #ff0;
    }
    28% {
      border-color: #0f0;
    }
    42% {
      border-color: #0ff;
    }
    57% {
      border-color: #00f;
    }
    71% {
      border-color: #f0f;
    }
    85% {
      border-color: #f00;
    }
    100% {
      border-color: #ff0;
    }
  }
