.user-dashboard-wrapper {
  font-family: Arial, sans-serif;
  color: #FFFFFF;
  background-color: #1e1f21;
  margin-left: 225px;
  width: 100%;
}

.user-dashboard-main-content {
  flex-grow: 1;
  padding: 20px;
}

.user-dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.user-dashboard-header-content {
  text-align: center;
}

.user-dashboard-greeting {
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  transition: color 200ms ease-in-out;
  text-align: center;
  font-weight: 400;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.user-dashboard-date {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #ffffff;
  transition: color 200ms ease-in-out;
  text-align: center;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.dropdown-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px auto;
  width: 550px; /* Adjust this value to change the width of the container */
  background-color: #252628; /* Background color for the container */
  border-radius: 60px;
  height: 48px;
  padding-right: 8px;
  transition: 200ms ease-in-out;
}

.parent-user-dashboard-widget-select-date-container {
  margin: 0 16px;
  display: block;
}

.child-user-dashboard-widget-select-date-container {
  background: transparent;
  color: #a2a0a2;
  fill: #a2a0a2;
  font-weight: 500;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  transition: color 200ms ease-in-out,fill 200ms ease-in-out;
  min-width: 1px;
  align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  user-select: none;

}

.user-dashboard-widget-select-date {
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-dashboard-widget-divider {
  border-right: 1px solid #424244;
  height: 24px;
  transition: border-right 200ms ease-in-out;
  margin-right: 20px;
}

.parent-user-dashboard-project-task-stats {
  margin-right: 50px;
  color: #a2a0a2;
  fill: #a2a0a2;
  transition: color 200ms ease-in-out,fill 200ms ease-in-out;
  display: flex;
}

.child-user-dashboard-project-task-stats {
  align-items: center;
  display: flex;
}

.user-dashboard-project-task-number-container {
  align-items: center;
  display: flex;
  width: 72px;
}

.user-dashboard-project-task-number-check-icon {
  overflow: hidden;
  height: 16px;
  width: 16px;
}

.parent-user-dashboard-project-task-number-ticker-container {
  margin: 0 6px;
}

.user-dashboard-project-task-number-ticker {
  font-size: 20px;
  line-height: 28px;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  color: #a2a0a2;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.user-dashboard-project-task-container-title {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #ffffff;
}

.user-dashboard-project-task-container-title-x {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.user-dashboard-project-quick-stats {
  display: flex;
}

.user-dashboard-project-quick-stats-title {
  font-size: 12px;
  line-height: 18px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.user-dashboard-project-task-container {
  display: flex;
  justify-content: space-between;
}

.user-dashboard-tasks-container {
  width: 48%;
  padding: 20px;
  border: 1px solid #424244;
  border-radius: 8px;
  box-shadow: #424244;
  border-color: #424244;
  background: #2a2b2d;
  outline: none;
  box-sizing: border-box;
  transition-duration: 200ms;
  transition-property: border-color, box-shadow;
  display: block;
  margin-right: 1%;
  margin-left: 2%;
}

.user-dashboard-tasks-container:hover {
  border-color: #4573d2;
  box-shadow: 0 0 5px 1px #4573d2;
}

.user-dashboard-projects-container {
  position: relative;
  width: 48%;
  padding: 20px;
  margin-right: 30px;
  border: 1px solid #424244;
  border-radius: 8px;
  box-shadow: #424244;
  border-color: #424244;
  background: #2a2b2d;
  outline: none;
  box-sizing: border-box;
  transition-duration: 200ms;
  transition-property: border-color, box-shadow;
  display: block;
  height: fit-content;
}

.user-dashboard-projects-container:hover {
  border-color: #4573d2;
  box-shadow: 0 0 5px 1px #4573d2;
}

.project-plus-button {
  position: absolute;
  right: 20px;
  top: 10px;
}

.project-plus-button:hover {
  cursor: pointer;
}
