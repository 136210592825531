.project-update-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #2a2b2d;
  width: 500px;
}

.project-update-form textarea {
  resize: vertical;
  max-height: 350px;
}

.project-update-form label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #eee;
}

.project-update-form input,
.project-update-form textarea {
  border-radius: 5px;
  border: 2px solid #eee;
  margin-bottom: 20px;
  background-color: #ffffff;
  color: black;
}

/* this is the button on the project update modal */

.project-update-form button {
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: #4c4c52;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 250ms ease;
  margin-top: 20px;
  width: fit-content;
  border: 1px solid #69696b;
  margin-left: auto;
}

.project-update-form button:hover {
  background-color: #47664a;
}

/* this is the button on the project show page */
.update-project-button {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: #2E2E30;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 250ms ease;
}

.update-project-button:hover {
  background-color: #4C4C4E;
}

.update-project-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #FFFFFF, 0 0 0 4px #2E2E30;
}

.update-project-button:active {
  background-color: #222224;
}

.project-update-form-title {
  font-size: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.project-update-form-date-wrapper {
  display: flex;
  justify-content: space-between;
}

.project-update-form-title-input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 10px;
}

.project-update-form-description-input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 10px;
}

.project-update-form-start-date-input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  margin-top: 5px;
  padding: 5px;
  cursor: pointer;
}

.project-update-form-end-date-input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  margin-top: 5px;
  padding: 5px;
  cursor: pointer;
}

.project-update-form-collaborators-input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
}

.project-update-form-title-input:hover,
.project-update-form-description-input:hover,
.project-update-form-start-date-input:hover,
.project-update-form-end-date-input:hover,
.project-update-form-collaborators-input:hover {
  border-color: #4573d2;
  box-shadow: 0 0 5px 1px #4573d2;
}
