.notificationHub {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    max-height: 650px;
    margin-top: 20px;
    background-color: transparent;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Add a box-shadow effect */
}

/* scroll stlying */
.notificationHub::-webkit-scrollbar {
    width: 10px;
}

.notificationHub::-webkit-scrollbar-track {
    background-color: transparent;
}

.notificationHub::-webkit-scrollbar-thumb {
    background-color: #3e3f42;
    border-radius: 20px;
}

.messageWrapper {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    padding: 5px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
}

.notificationX:hover {
    cursor: pointer;
}

.notifications-title {
    display: flex;
    justify-content: space-between;
}

.notifications-title:hover {
    cursor: pointer;
}


.notificationMessage:hover {
    cursor: pointer;
}

.notification-count {
    display: flex;
    color: white;
    border-radius: 50%;
    background-color: #00aeef;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 10px;
}

.notifications-mainwrapper {
    padding-left: 20px;
}