#modal-content {
    position: fixed;
    background: color #2a2b2d;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2a2b2d;
    padding: 20px;
    border: 1px solid #424244;
    border-radius: 5px;
    margin: 20px;
    font-size: 18px;
    color: white;
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
