.project-home-wrapper {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background-color: #1e1f21;
  color: white;
  grid-column: auto;
  text-align: center;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  overflow: hidden;
}

.centered-container {
  background-color: #1e1f21;
  margin-left: 225px;
  width: calc(100% - 225px);
}

.project-home-wrapper button:hover {
  background-color: #6c9da9;
}

.project-home-wrapper button:active {
  background-color: #6c9da9;
}

.project-home-wrapper form {
  display: flex;
  color: black;
  width: 75%;
  margin: 10px;
}

.project-home-wrapper .recommended-tasks {
  background-color: #2e2e30;
  color: white;
}

h1.project-home-table-title {
  margin: 10px;
  font-size: 30px;
}

h2.project-home-table-title {
  margin: 10px;
  font-size: 24px;
}

/* This has to do with the date line */

.date-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* Center the date line container horizontally */
  position: relative;
  width: 30%;
  margin-bottom: 40px;
  margin-top: 40px;
}

.date-line {
  flex-grow: 1;
  height: 4px;
  background-color:rgba(255, 255, 255, 0.2);
}

.date-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00aeef;
  position: absolute;
  top: -3;
  left: 50%; /* Set the dot to the middle of the container */
  transform: translateX(-50%);
}

.date-label {
  font-size: 16px;
}

.start-date {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 10px;
}

.end-date {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
}

.today {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-bottom: 10px;
  transform: translateX(-50%);
}

.recommended-tasks {
  margin-left: 50px;
}

.slider {
  padding-left: 50px;
  width: 90%;
}

.slider p {
  font-size: 16px;
  margin: 10px;
  padding: 10px;
  color: white;
}

.project-show-filter-group {
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  margin-top: 10px;
}

.project-show-filter-group-title {
  margin-right: 10px;
  font-weight: lighter;
  color: #e0e0e0;
}

.project-show-filter-select {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.project-show-filter-select:hover {
  border-color: #4573d2;
  box-shadow: 0 0 5px 1px #4573d2;
}
