/* This changes the style for the whole page */
.hero-page-holder {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hero-navbar {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.marketing-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.hero-slogan {
  
}

/* without this they are stacked */
.hero-page-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  height: 35px;
  padding: 4px;
  width: 200px;
}

.hero-header-nav-bar-logo {
  margin-right: 5%;
  height: 50px;
}

.hero-main-gif {
  margin-right: 80px;
}

.nav-buttons button {
  background-color: #0D0E10;
  color: #FFFFFF;
  border-width: 1px;
  font-size: 14px;
  margin-left: 20px;
  white-space: nowrap;
  margin-bottom: 0;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  height: 36px;
  letter-spacing: 0.5px;
  line-height: 36px;
  padding: 0 16px;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms, color 150ms ease-in-out;
  touch-action: manipulation;
  position: relative;
  overflow: hidden;
}

.nav-buttons button:hover {
  background-color: #ff5263;
  color: black;
}

.nav-buttons button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust the transparency and color as needed */
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.hero-slogan {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  margin: 50px 0;
}

.hero-footer {

  justify-content: center;
  padding: 20px;
  color: #666666;

}

.hero-footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hero-footer-team-member {
  margin: 10px;
  text-align: center;
  font-size: 14px;
}

.hero-footer-social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.hero-footer-social-links a {
  margin: 5px;
}

.hero-footer-text {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: #999999;
}

.hero-footer-github-logo {
  height: 20px;
  border: 0;
  overflow: clip;
  margin-right: 2px;
}

.hero-footer-linkedin-logo {
  height: 20px;
  overflow: clip;
}
