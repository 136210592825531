.user-project-index {
    /* width: 100vw; */
    padding-left: 10px;
    padding-right: 10px;
}

.user-project-index ul {
    justify-content: center;
    align-items: center;
    max-width: 75%;
}

.user-project-index li {
    border: 1px solid rgb(244, 244, 248);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.task-create-forms {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Two columns with equal width */
    grid-gap: 0px;
    /* Gap between columns */
    max-width: 90%;
}

.task-timeline-link {
    height: 14px;
}

.icon-button {
    height: 14px;
    background-color: transparent;
    border: none;
    width: 20px;
    padding: unset;
    transition: transform 250ms ease-out;
}

.icon-button:hover {
    cursor: pointer;
    transform: scale(1.4);
}

.user-home-project-index-wrapper {
    /* max-height: 500px;
    overflow: scroll; */
}

.user-home-project-index-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.user-project-index-new-user-message {
    margin-top: 10px;
}
