.hero-page-signup-terms {
  margin-bottom: 16px;
  font-size: 12px;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
}

.hero-page-signup-form {
  padding: 20px;
  padding-right: 38px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.hero-page-signup-form-text {
  font-size: calc(33.75px + (45 - 33.75) * ((100vw - 2000px) / (1280 - 480)));
  line-height: 1.244;
  color: #151B26;
  font-weight: normal;
  margin-top: 0;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
}

.signup-form-input {
  background-color: #FFFFFF;
  border: 2px solid #E0E6E8;
  border-radius: 3px;
  height: 40px;
  line-height: normal;
  margin: 0;
  padding: 0 0 0 16px;
  transition: border 150ms;
  width: 100%;
  touch-action: manipulation;
  overflow: visible;
  transition: box-shadow 0.3s;
  margin-top: 2%;
}

.signup-form-input:hover {
  /* Add drop shadow effect on hover */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.signup-form-input:focus::placeholder {
  /* Shrink placeholder text when clicked */
  font-size: 0.8em;
}


.signup-form-button {
  margin-top: 16px;
  width: 104%;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  background-color: #0D0E10;
  border-radius: 3px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms, color 150ms ease-in-out;
  white-space: nowrap;

}

.signup-form-button:hover {
  background-color: #ff5263;
  color: black;
}


.password-input-container {
  position: relative;
}

.password-toggle-button {
  position: absolute;
  top: 60%;
  right: 5px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.signup-form-errors {
  color: red;
  font-size: 12px;
  margin-left: 1%;
}
