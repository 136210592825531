.gantt-filter-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  /* border-bottom: 1.4px solid #e0e0e0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}

.timeline-project-title {
  color: #e0e0e0;
  font-weight: lighter;
  font-size: 25px;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
}

.gantt-filter-group {
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: aliceblue;
  margin-left: 10px;
}

.gantt-filter-group-title {
  margin-right: 10px;
  font-weight: lighter;
  color: #e0e0e0;
  margin-left: 10px;
}

.gantt-filter-select {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.gantt-timeframe-filter {
  height: 10px;
}

.gantt-filter-select:hover {
  border-color: #4573d2;
  box-shadow: 0 0 5px 1px #4573d2;
}

.gantt-chart-instructions-container {
  margin-left: 20px;
}

.gantt-chart-instructions-label {
  color: #e0e0e0;
  margin-bottom: 5px;
  font-size: 20px;
}

.gantt-chart-instructions-item {
  color: #e0e0e0;
  margin-bottom: 2px;
  list-style-type: disc;
  display: list-item;
  margin-left: 20px;
}
