.side-nav {
  width: 225px;
  background-color: #2e2e30;
  font-family: Arial, sans-serif;
  color: #FFFFFF;
  transition: transform 0.3s ease;
  position: fixed;
  height: 100vh;
  overflow: hidden;
}

.nav-company-logo-container {
  display: flex;
  padding-left: 10px;
  padding-top: 20px;
}

.nav-company-logo-container > img {
  height: 35px;
  padding: 4px;
  width: 88px;
}

.nav-list-item {
  padding-left: 20px;
  margin: 15px 0;
  font-family: Arial, sans-serif;
  color: #FFFFFF;
}

.nav-link {
  color: #F5F4F3;
  text-decoration: none;
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  display: flex;
  align-items: center;
}

.nav-icon {
  overflow: hidden;
  fill: #a2a0a2;
  margin-right: 8px;
  transition: transform 250ms ease-out;
  height: 20px;
  width: 20px;
}

.nav-text {
  display: inline-block;
}

.nav-bar-logout-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease;
  padding-top: 15px;
  padding-bottom: 10px;
  position: absolute;
  bottom: 10px;
  left: 65px;
}

.nav-bar-logout-button:hover {
  transform: scale(1.1);
  background-color: transparent !important;
}

.nav-bar-logout-button:hover::after {
  content: 'Logout';
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  padding-left: 8px;
  font-size: 14px;
  color: #FFFFFF;
}

.user-initial-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #2a2b2d;
  font-weight: bold;
  font-size: 16px;
  margin-left: 8px;
  position: absolute;
  bottom: 20px;
  left: 20px;
}


.nav-custom-hr {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Adjusts the thickness of the line */
  width: calc(100% + 2 * 20px); /* handles the padding so it fits the whole container */
  margin-left: -20px;
  margin-right: -20px;
}

.notifications {
  margin-top: 20px;
}

.nav-bar-notifications {

}

.nav-wrapper {
  display: flex;
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.nav-wrapper .nav-text {
  margin-top: 3px;
}

.bottom-left-nav {
  /* position: fixed;
  bottom: 0;
  left: 0; */
}