.timeline-wrapper {
  display: flex;
  height: 100vh;
}

.gantt-chart-wrapper {
  margin-left: 225px;
  background-color: #1e1f21;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.gantt-chart-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
