.task-update-form {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #2a2b2d;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.task-update-form label {
  font-size: 18px;
  font-weight: bold;
  color: #eee;
}

.task-update-form input[type="text"],
.task-update-form textarea {
  width: 95%;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #eee;
  margin-bottom: 20px;
  background-color: #ffffff;
  color: black;
}

.task-update-form textarea {
  resize: vertical;
  max-height: 350px;
}

.task-update-form-submit-button {
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: #4c4c52;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 250ms ease;
  margin-top: 20px;
  width: fit-content;
  border: 1px solid #69696b;
  margin-left: auto;
}

.task-update-form-submit-button:hover {
  background-color: #47664a;
}

.task-update-progress-label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.task-update-form-title-input {
  margin-top: 5px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.task-update-form-description-container {
  margin-top: 5px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.task-update-form-status-input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  width: fit-content;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  background-color: #ffffff;
  color: black;
  cursor: pointer;
}

.task-update-form-start-date-input-container {
  width: fit-content;
}

.task-update-form-due-date-input-container {
  width: fit-content;
}

.task-update-form-start-date-input {
  margin-top: 5px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  background-color: #ffffff;
  color: black;
  cursor: pointer;
}

.task-update-form-due-date-input {
  margin-top: 5px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  background-color: #ffffff;
  color: black;
  cursor: pointer;
}

.task-update-form-header {
  font-size: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.task-update-form-assignee-input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  width: fit-content;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  background-color: #ffffff;
  color: black;
  cursor: pointer;
}

.sub-task-update-input-form-container {
  display: flex;
  justify-content: space-between;
}

.task-update-form-blocking-tasks-label {
  margin-top: 5px;
  margin-bottom: 5px;
}

.task-update-progress-input-bar {
  cursor: pointer;
}

.task-update-form-blocking-tasks-input {
  cursor: pointer;
}

.task-update-form-title-input:hover,
.task-update-form-description-container:hover,
.task-update-form-status-input:hover,
.task-update-form-start-date-input:hover,
.task-update-form-due-date-input:hover,
.task-update-form-assignee-input:hover,
.task-update-form-blocking-tasks-input:hover {
  border-color: #4573d2;
  box-shadow: 0 0 5px 1px #4573d2;
}
