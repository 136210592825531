.task-table {
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: .375rem;

}

.task-table-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 2px;
    background-color: #2A2B2D;
    font-weight: bold;
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
}

.no-hover-ut:hover {
    cursor: unset;
    background-color: #2A2B2D;
}

.user-home-task-table-wrapper {
    /* max-height: 500px;
    overflow: scroll; */
}

.user-home-task-table-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.user-task-index-new-user-message {
    margin-top: 10px;
}
